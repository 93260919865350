/**
 * Created by decipaliz on 22.05.2024.
 */
var PrizeCalendarWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        this.prizeCalendar = new PrizeCalendar(mission);

        this._super({
            title: "PrizeCalendarWindow.Title",
            content: this.createContent(),
            name: "prizecalendarwindow",
            openSound: bundles.prizecalendar.urls.window_effect
        });
        this.createBg();
        this.createArrows();

        Mission.logEvent(cleverapps.EVENTS.MISSION_OPEN, mission.getName());
    },

    createContent: function () {
        var styles = cleverapps.styles.PrizeCalendarWindow;

        this.createTiles();

        var timer = new Timer(this.mission.getTimeLeft(), this.close.bind(this), {
            timerBg: bundles.prizecalendar.frames.big_timer_bg_png,
            timerIcon: bundles.prizecalendar.frames.big_timer_png
        });

        var tilesLayout = this.tilesLayout = new cleverapps.GridLayout(this.tiles, {
            columns: PrizeCalendar.TILES_IN_ROW,
            margin: styles.tiles.margin,
            padding: styles.tiles.padding
        });

        var content = [timer, tilesLayout];
        if (["heroes", "runes"].includes(cleverapps.config.name)) {
            content.reverse();
        }

        return new cleverapps.Layout(content, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.windowPadding
        });
    },

    createTiles: function () {
        this.tiles = [];
        this.prizeCalendar.tiles.forEach(function (tile, i) {
            var row = Math.floor(i / PrizeCalendar.TILES_IN_ROW);

            var tileView = new PrizeCalendarTileView(tile);
            if (row % 2 === 1) {
                this.tiles.splice(row * PrizeCalendar.TILES_IN_ROW, 0, tileView);
            } else {
                this.tiles.push(tileView);
            }
        }.bind(this));
    },

    createBg: function () {
        var styles = cleverapps.styles.PrizeCalendarWindow;
        if (styles.rainbowBg) {
            var rainbowBg = new cc.Sprite(bundles.prizecalendar.frames.rainbow_bg);
            rainbowBg.setScale(styles.rainbowBg.scale);
            rainbowBg.setPositionRound(styles.rainbowBg);
            this.content.addChild(rainbowBg, -1);
            return;
        }

        if (styles.bg) {
            var outerBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.window_outer_bg);

            if (bundles.prizecalendar.jsons.pattern_json) {
                var pattern = new cleverapps.Spine(bundles.prizecalendar.jsons.pattern_json);
                pattern.setAnimation(0, "animation", true);

                pattern.setPositionRound(styles.bg.pattern);

                outerBg.addChild(pattern);
            }

            outerBg.setContentSize2(styles.bg.outerBg);

            for (var i = 0; i < 4; i++) {
                var decor = new cc.Sprite(bundles.prizecalendar.frames.window_bg_decor);

                decor.setPositionRound(styles.bg.decorPositions[i]);
                outerBg.addChild(decor);
            }

            outerBg.setPositionRound(styles.bg.outerBg);
            this.content.addChild(outerBg, -1);
        }
    },

    createShadow: function () {
        var shadow = cleverapps.UI.createScale9Sprite(bundles.windows.frames.window_bg_png);
        shadow.setColor(cleverapps.styles.COLORS.BLACK);
        shadow.setOpacity(0);

        var padding = cleverapps.styles.PrizeCalendarWindow.shadow.padding;
        var rect = this.getContentSize();
        shadow.setContentSize(rect.width + 2 * padding, rect.height + 2 * padding);

        shadow.setPositionRound(this.getPosition());
        shadow.setLocalZOrder(1);
        this.addChild(shadow);

        return shadow;
    },

    onChestAnimation: function (rewards) {
        this.createShadow().runAction(new cc.Sequence(
            new cc.FadeTo(0.5, 100),
            new cc.CallFunc(function () {
                var prize = new cleverapps.Spine(bundles.prizecalendar.jsons.prize_json);
                prize.setSkin("chest5");
                prize.setPositionRound({ align: "center" }, { align: "center" });
                prize.setLocalZOrder(1);
                prize.setAnimation(0, "open_chest", false);
                this.addChild(prize);

                cleverapps.audio.playSound(bundles.prizecalendar.urls.reward_effect);

                prize.setCompleteListenerOnce(function () {
                    prize.setAnimation(0, "reward", false);

                    rewards.collectRewardsAnimation(prize, {
                        flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,
                        allAtOnce: true,
                        small: false,
                        beginScale: 1
                    });

                    this.runAction(new cc.Sequence(
                        new cc.DelayTime(cleverapps.styles.PrizeCalendarWindow.prizeDelay),
                        new cc.CallFunc(function () {
                            this.close();
                        }.bind(this))
                    ));
                }.bind(this));
            }.bind(this))
        ));
    },

    createArrows: function () {
        for (var i = 0; i < this.tiles.length - 1; i++) {
            this.createArrow(this.tiles[i]);
        }
    },

    createArrow: function (tileView) {
        var arrow;
        if (bundles.prizecalendar.frames.arrow_png) {
            arrow = new cc.Sprite(bundles.prizecalendar.frames.arrow_png);
        } else if ([PrizeCalendarTile.STATE_LOCKED, PrizeCalendarTile.STATE_WAITING].indexOf(tileView.tile.state) !== -1) {
            arrow = new cc.Sprite(bundles.prizecalendar.frames.arrow_locked);
        } else {
            arrow = new cc.Sprite(bundles.prizecalendar.frames.arrow_unlocked);
        }

        var margin = cleverapps.styles.PrizeCalendarWindow.tiles.margin;
        if (cleverapps.config.name === "riddles") {
            margin = { x: 0, y: 0 };
        }

        tileView.setArrow(arrow);
        var position = tileView.getPosition();
        switch (this.getArrowOrientation(this.prizeCalendar.getTileIndex(tileView.tile))) {
            case PrizeCalendarWindow.ARROW_DOWN:
                arrow.setRotation(90);
                arrow.setScaleY(-arrow.scaleY);
                position.y -= (tileView.height + margin.y) / 2;
                break;
            case PrizeCalendarWindow.ARROW_LEFT:
                arrow.setScaleX(-arrow.scaleX);
                position.x -= (tileView.width + margin.x) / 2;
                break;
            default:
                position.x += (tileView.width + margin.x) / 2;
                break;
        }

        arrow.setPosition(position);

        if (cleverapps.config.name === "heroes") {
            arrow.setLocalZOrder(-1);
        }
        this.tilesLayout.addChild(arrow);
    },

    getArrowOrientation: function (index) {
        switch (index) {
            case 2: case 5:
                return PrizeCalendarWindow.ARROW_DOWN;
            case 3: case 4:
                return PrizeCalendarWindow.ARROW_LEFT;
            default:
                return PrizeCalendarWindow.ARROW_RIGHT;
        }
    },

    listBundles: function () {
        return ["prizecalendar"];
    }
});

PrizeCalendarWindow.ARROW_RIGHT = 0;
PrizeCalendarWindow.ARROW_DOWN = 1;
PrizeCalendarWindow.ARROW_LEFT = 2;

cleverapps.styles.PrizeCalendarWindow = {
    margin: 30,
    windowPadding: {
        bottom: 40
    },

    tiles: {
        margin: {
            x: 40, y: 50
        },
        padding: {
            x: 20
        }
    },
    timer: {
        width: 250,
        height: 50
    },
    button: {
        width: 300,
        height: 120
    },
    prizeDelay: 3,
    shadow: {
        x: { align: "center" },
        y: { align: "center" },

        padding: 20
    }
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PRIZE_CALENDAR: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});
