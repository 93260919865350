/**
 * Created by slava on 2/9/19
 */

var Building = function (farm, data, id) {
    cleverapps.EventEmitter.call(this);
    this.farm = farm;
    this.id = id;
 
    this.quests = [];
    this.provides = "";

    this.open = false;

    this.level = 0;
    this.label = undefined;

    this.init(data);

    this.currentQuestId = 0;
};

Building.prototype = Object.create(cleverapps.EventEmitter.prototype);
Building.prototype.constructor = Building;

Building.prototype.canStillProvideQuestItems = function () {
    if (this.farm.isCompleted()) {
        return false;
    }
    var curQuest = this.farm.getBuilding().getQuest();
    return curQuest.getStatus().some(function (item) {
        return this.provides === item.name && item.status < item.amount;
    }.bind(this));
};

Building.prototype.levelUp = function () {
    var nextLevel = cleverapps.meta.getMainObject().chooseNextLevel();
    this.label = {
        episodeNo: nextLevel.episodeNo,
        levelNo: nextLevel.levelNo
    };
    this.level++;

    this.farm.earnKeys(this.provides);

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
    cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);

    cleverapps.playButton.onIncLevel();

    return this.provides;
};

Building.prototype.init = function (data) {
    Object.assign(this, data);

    this.quests = data.stages.map(function (questData) {
        return new Quest(this.farm, questData);
    }, this);
};

Building.prototype.load = function (data) {
    this.open = true;
    this.level = data.level;
    this.label = data.label;
};

Building.prototype.save = function () {
    return {
        level: this.level,
        label: this.label
    };
};

Building.prototype.isCurrent = function () {
    return this.farm.getBuilding() === this;
};

Building.prototype.isOpen = function () {
    return this.open;
};

Building.prototype.amountStages = function () {
    return this.quests.length;
};

Building.prototype.getStage = function () {
    return this.currentQuestId;
};

Building.prototype.hasChoices = function () {
    return false;
};

Building.prototype.isLastStage = function (stage) {
    if (stage === undefined) {
        stage = this.currentQuestId;
    }
    return this.quests[stage] === undefined;
};

Building.prototype.isComplete = function () {
    return this.getQuest() === undefined || this.id < this.farm.currentBuildingId;
};

Building.prototype.getQuest = function () {
    return this.quests[this.currentQuestId];
};

Building.prototype.nextQuest = function () {
    this.currentQuestId++;

    if (this.isComplete()) {
        this.open = true;
        return false;
    }

    return true;
};

Building.prototype.getStageIcon = function () {
    return undefined;
};

Building.prototype.removeHighlight = function () {
    this.trigger("removeHighlight");
};

Building.prototype.addHighlight = function () {
    this.trigger("addHighlight");
};

Building.prototype.canTouch = function () {
    return true;
};

Building.prototype.onClick = function () {
    cleverapps.focusManager.display({
        focus: "BuildingClick",
        action: function (f) {
            if (this.isCurrent()) {
                new QuestWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            } else if (this.isOpen()) {
                if (this.farm.buildings.length - this.id === 1) {
                    new AllDoneWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                } else {
                    MethaHelper.start(f, MethaHelper.getCurrentLevel(this));
                }
            } else {
                new BuildingLockedWindow(this);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        }.bind(this)
    });
};